import React, { Component } from "react";

type PropsType = {
    theme?: {
        fill: string;
        text: string;
        type: "light" | "dark";
    };
};

const withForm = (WrappedComponent: Component) => props => {
    let { theme, ...restProps } = props;

    if (theme === undefined || theme === null) {
        theme = {
            fill: "inherit",
            text: "inherit",
            type: "dark",
        };
    }

    return <WrappedComponent theme={theme} {...restProps} />;
};

export default withForm;
export { PropsType };
