import React, { Component } from "react";
import animateScrollTo from "animated-scroll-to";
import withTheme, { PropsType as withThemeProps } from "../../hoc/withTheme";
import "./style.scss";

type SubNavItem = withThemeProps & {
    url: string;
    title: string;
    enabled: boolean;
    active?: boolean;
    target?: string;
};

type PropsType = {
    items: Array<SubNavItem>;
    isSticky: boolean;
};

class SubNavigation extends Component<PropsType> {
    private scrollTo(scrollTarget: string): void {
        const block = document.getElementById(scrollTarget);
        const options = {
            speed: 500,
            minDuration: 250,
            maxDuration: 1500,
            offset: -160,
            cancelOnUserAction: true,
            passive: true,
        };

        if (block) {
            animateScrollTo(block, options);
        }
    }

    public render(): JSX.Element {
        const items = this.props.items === undefined ? [] : this.props.items;
        const theme = this.props.theme.text === undefined ? "inherit" : this.props.theme.text;
        let className = "SubNavigation";

        if (this.props.isSticky) {
            className += " SubNavigation--sticky";
        }

        if (this.props.theme.text !== undefined) {
            className += " SubNavigation--themed";
        }

        return (
            <div className={className} style={{ color: theme }}>
                <div className="SubNavigation__items">
                    {items.map(item => {
                        let className = "SubNavigation__item";
                        if (item.active) className += " SubNavigation__item--active";
                        if (item.enabled)
                            return item.url !== undefined && item.url.length ? (
                                <a key={item.id} href={item.url} className={className}>
                                    {item.title}
                                </a>
                            ) : (
                                <button
                                    key={item.id}
                                    onClick={(): void => this.scrollTo(item.target)}
                                    href={item.url}
                                    className={className}
                                >
                                    {item.title}
                                </button>
                            );
                    })}
                </div>
            </div>
        );
    }
}

export default withTheme(SubNavigation);
export { PropsType, SubNavItem };
