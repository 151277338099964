import React, { SFC } from "react";
import withContentWrap, { PropsType as WithContentWrapProps } from "../../hoc/withContentWrap";
import Icon from "../Icon";
import "./style.scss";

type PropsType = WithContentWrapProps &
    HTMLProps<HTMLDivElement> & {
        col1: string;
        col2: string;
        col3: string;
    };

const Footer = (props): SFC => {
    return (
        <div className="Footer">
            <div className="Footer__logo">
                <Icon icon="logo" stretch={true} />
            </div>
            <div className="Footer__col" dangerouslySetInnerHTML={{ __html: props.col1 }} />
            <div className="Footer__col" dangerouslySetInnerHTML={{ __html: props.col2 }} />
            <div className="Footer__col" dangerouslySetInnerHTML={{ __html: props.col3 }} />
        </div>
    );
};

export default withContentWrap(Footer);
