import React, { SFC, JSX } from "react";
import withTheme, { PropsType as withThemeProps } from "../../hoc/withTheme";
import Icon, { PropsType as IconProps } from "../Icon";
import Image, { PropsType as ImageProps } from "../Image";
import "./style.scss";

type PropsType = HTMLProps<HTMLElement> &
    withThemeProps & {
        iconAlign?: "left" | "right";
        textAlign?: "left" | "center" | "right";
        icon?: string;
        size?: "medium" | "large";
        text?: string;
        title?: string;
        src?: string;
        srcSet?: string;
        url?: string;
    };

const Tile: SFC<PropsType> = (props: PropsType): JSX.Element => {
    const iconAlignClass = props.iconAlign ? ` Tile--icon-${props.iconAlign}` : "";
    const textAlignClass = props.textAlign ? ` Tile--text-${props.textAlign}` : "";
    const sizeClass = props.size ? ` Tile--${props.size}` : " Tile--large";
    const themeClass = props.theme.fill === "inherit" ? "" : ` Tile--themed`;
    const linkedClass = props.url && props.url.length > 0 ? ` Tile--linked` : "";
    const className = `Tile${iconAlignClass}${textAlignClass}${sizeClass}${themeClass}${linkedClass}`;
    const Element = props.url && props.url.length > 0 ? "a" : "div";
    const anchorProps =
        props.url && props.url.length > 0
            ? {
                  href: props.url,
              }
            : {};

    return (
        <Element
            {...anchorProps}
            id={props.id}
            className={className}
            style={{ color: props.theme.fill ? props.theme.fill : "inherit" }}
        >
            {!props.src && (
                <div className="Tile__content">
                    <div className="Tile__content-wrap">
                        {props.title && <div className="Tile__title">{props.title}</div>}
                        {props.icon &&
                            props.size !== "medium" && (
                                <div className="Tile__icon">
                                    <div className="Tile__icon-wrap">
                                        <Icon customSvg={props.icon} stretch />
                                    </div>
                                </div>
                            )}
                        <div className="Tile__text" dangerouslySetInnerHTML={{ __html: props.text }} />
                    </div>
                </div>
            )}
            {props.icon &&
                props.size === "medium" && (
                    <div className="Tile__icon">
                        <div className="Tile__icon-wrap">
                            <Icon customSvg={props.icon} />
                        </div>
                    </div>
                )}
            {props.src && (
                <div className="Tile__image">
                    <div className="Tile__image-wrap">
                        <img src={props.src} srcSet={props.srcSet} alt={props.title} />
                    </div>
                </div>
            )}
        </Element>
    );
};

export default withTheme(Tile);
