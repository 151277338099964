import React, { SFC } from "react";
import "./style.scss";

type PropsType = {
    isOpen: boolean;
    action(): void;
};

const NavButton = (props: PropsType): SFC => {
    const openClass = props.isOpen ? " NavButton--open" : " NavButton--close";
    const className = `NavButton${openClass}`;

    return (
        <button type="button" className={className}>
            <div className="NavButton__close-icon">
                <span />
                <span />
            </div>
            <div className="NavButton__open-icon">
                <span />
                <span />
                <span />
            </div>
        </button>
    );
};

export default NavButton;
export { PropsType };
