import React, { SFC } from "react";
import ClassName from "../../utility/ClassName";
import "./style.scss";

type PropsType = {
    size?: "default" | "small" | "medium" | "large";
};

const ContentWrap = (props): SFC => {
    const size = props.size ? props.size : "default";
    const className = `ContentWrap ContentWrap--${size}`;

    return <div className={className}>{props.children}</div>;
};

export default ContentWrap;
