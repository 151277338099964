import React, { Component } from "react";
import Navigation, { NavItem } from "../../components/Navigation";
import { SubNavItem } from "../../components/SubNavigation";
import Footer from "../../components/Footer";
import Spacer from "../../components/Spacer";
import Tile from "../../components/Tile";
import "./style.scss";
import ContentWrap from "../../components/ContentWrap";

type PropsType = {
    mainNav?: Array<NavItem>;
    subNav?: Array<SubNavItem>;
    tiles?: Array<Object>;
    footer?: {
        column1: string;
        column2: string;
        column3: string;
    };
};

class Home extends Component<PropsType, {}> {
    public render() {
        const mainNav = this.props.mainNav ? this.props.mainNav : [];
        const tiles = this.props.tiles ? this.props.tiles : [];

        return (
            <div className="Home">
                <Navigation items={mainNav} />
                <ContentWrap>
                    <div className="Home__tiles">
                        {tiles.map(tile => {
                            return (
                                <Tile
                                    key={tile.id}
                                    size={tile.size}
                                    theme={tile.theme}
                                    title={tile.title}
                                    text={tile.text}
                                    url={tile.url}
                                    icon={tile.icon}
                                    src={tile.src}
                                    srcSet={tile.srcSet}
                                    iconAlign={tile.iconAlign}
                                    textAlign={tile.textAlign}
                                />
                            );
                        })}
                    </div>
                </ContentWrap>
                <Footer
                    col1={this.props.footer.column1}
                    col2={this.props.footer.column2}
                    col3={this.props.footer.column3}
                />
            </div>
        );
    }
}

export default Home;
