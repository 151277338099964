import React, { Component } from "react";
import ContentWrap, { PropsType as ContentWrapType } from "../../components/ContentWrap";

type PropsType = {
    contentWidth?: ContentWrapType["size"];
};

const withContentWrap = WrappedComponent => {
    return class WithContentWrap extends Component<PropsType> {
        render() {
            const { contentWidth, ...passThroughProps } = this.props;

            return (
                <ContentWrap size={this.props.contentWidth}>
                    <WrappedComponent {...passThroughProps} />
                </ContentWrap>
            );
        }
    };
};

export default withContentWrap;
export { PropsType };
