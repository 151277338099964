import { hydrate } from "react-dom";
import React from "react";
import Home from "./pages/Home";
import "normalize.css";
import "./common/styles/global.scss";

hydrate(
    <Home mainNav={craftInstance.mainNav} tiles={craftInstance.tiles} footer={craftInstance.footer} />,
    document.getElementById("react-home"),
);

if (module.hot) {
    module.hot.accept();
}
