import React, { Component } from "react";
import Icon from "../Icon";
import ContentWrap from "../ContentWrap";
import NavButton from "../NavButton";
import withTheme, { PropsType as WithThemeProps } from "../../hoc/withTheme";
import SubNavigation, { PropsType as SubNavigationProps } from "../SubNavigation";
import "./style.scss";

type NavItem = {
    url: string;
    title: string;
    enabled: boolean;
    active: boolean;
};

type PropsType = WithThemeProps & {
    items: Array<NavItem>;
    subItems?: Array<SubNavigationProps>;
};

type StateType = {
    isSticky: boolean;
    isOpen: false;
};

class Navigation extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);

        this.state = {
            isSticky: false,
            isOpen: false,
        };

        this.checkPosition = this.checkPosition.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    public componentDidMount() {
        this.checkPosition();
        window.addEventListener("scroll", this.checkPosition);
    }

    public componentWillUnmount() {
        window.removeEventListener("scroll", this.checkPosition);
    }

    private checkPosition() {
        this.setState({ isSticky: window.scrollY > 32 ? true : false });
    }

    private toggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    public render(): JSX.Element {
        const items = this.props.items === undefined ? [] : this.props.items;
        let className = "Navigation";

        if (this.props.theme.fill !== "inherit") {
            className += " Navigation--themed";
        }

        if (this.props.theme.type === "light") {
            className += " Navigation--light";
        }

        if (this.state.isSticky) {
            className += " Navigation--sticky";
        }

        if (this.state.isOpen) {
            className += " Navigation--open";
        }

        return (
            <div className={className} style={{ color: this.props.theme.fill ? this.props.theme.fill : "inherit" }}>
                <ContentWrap>
                    <div className="Navigation__wrap">
                        <div className="Navigation__bar">
                            <a href="/" className="Navigation__logo">
                                <div className="Navigation__logo-small">
                                    <Icon stretch={true} icon="logo" />
                                </div>
                                <div className="Navigation__logo-large">
                                    <Icon stretch={true} icon="logoText" />
                                </div>
                            </a>

                            <div className="Navigation__close" onClick={this.toggle} title="Toggle main menu">
                                <NavButton action={this.toggle} isOpen={this.state.isOpen} />
                            </div>
                        </div>

                        <div className="Navigation__items">
                            {items.map(item => {
                                let className = "Navigation__item";
                                if (item.active) className += " Navigation__item--active";
                                if (item.enabled)
                                    return (
                                        <a key={item.id} href={item.url} className={className}>
                                            {item.title}
                                        </a>
                                    );
                            })}
                        </div>
                    </div>
                    {this.props.subItems !== undefined &&
                        this.props.subItems.length > 0 && (
                            <div className="Navigation__subnavigation">
                                <SubNavigation
                                    items={this.props.subItems}
                                    theme={this.props.theme}
                                    isSticky={this.state.isSticky}
                                />
                            </div>
                        )}
                </ContentWrap>
            </div>
        );
    }
}

export default withTheme(Navigation);
export { NavItem };
