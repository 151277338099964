import React, { SFC } from "react";
import Icons from "./types";
import "./style.scss";

type PropsType = {
    icon?: keyof typeof Icons;
    customSvg?: string;
    color?: string;
    stretch?: boolean;
};

const Icon: SFC<PropsType> = (props): JSX.Element => {
    let className = "Icon";
    if (props.stretch) className += " Icon--stretch";
    const icon = props.customSvg ? props.customSvg : Icons[props.icon];

    return (
        <div
            className={className}
            aria-hidden
            role="img"
            style={{ color: props.color ? props.color : "currentColor" }}
            dangerouslySetInnerHTML={{ __html: icon }}
        />
    );
};

export default Icon;
export { Icons };
