/* tslint:disable */
enum Icons {
    bars = require("./assets/bars.svg"),
    caretDown = require("./assets/caret-down.svg"),
    caretLeft = require("./assets/caret-left.svg"),
    caretRight = require("./assets/caret-right.svg"),
    caretUp = require("./assets/caret-up.svg"),
    checkmarkCircle = require("./assets/checkmark-circle.svg"),
    checkmark = require("./assets/checkmark.svg"),
    chevronDownCircle = require("./assets/chevron-down-circle.svg"),
    chevronLeftCircle = require("./assets/chevron-left-circle.svg"),
    chevronRightCircle = require("./assets/chevron-right-circle.svg"),
    chevronUpCircle = require("./assets/chevron-up-circle.svg"),
    chevronDown = require("./assets/chevron-down.svg"),
    chevronLeft = require("./assets/chevron-left.svg"),
    chevronRight = require("./assets/chevron-right.svg"),
    chevronUp = require("./assets/chevron-up.svg"),
    close = require("./assets/close.svg"),
    facebook = require("./assets/facebook.svg"),
    instagram = require("./assets/instagram.svg"),
    linkedin = require("./assets/linkedin.svg"),
    logo = require("./assets/logo.svg"),
    logoPadded = require("./assets/logoPadded.svg"),
    logoText = require("./assets/logoText.svg"),
    twitter = require("./assets/twitter.svg"),
    youtube = require("./assets/youtube.svg"),
    zoomIn = require("./assets/zoom-in.svg"),
    zoomOut = require("./assets/zoom-out.svg"),
    commissie = require("./assets/commissie.svg"),
    ongemakkelijk = require("./assets/ongemakkelijk.svg"),
    ongewenst = require("./assets/ongewenst.svg"),
    partners = require("./assets/partners.svg"),
    pesten = require("./assets/pesten.svg"),
    respectloos = require("./assets/respectloos.svg"),
    schuldig_schaamte = require("./assets/schuldig_schaamte.svg"),
    vrolijk_tevreden = require("./assets/vrolijk_tevreden.svg"),
}

export default Icons;
